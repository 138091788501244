.offer {
    position: relative;
    margin: 0 45px;
    padding-top: 10px;
    @media only screen and (max-width: $screen-sm - 1px) {
        margin: 0 10px;
    }
    &__start {
        background: white;
        max-width: $break;
        width: 100%;
        margin: 20px auto;
        height: 163px;
        padding: 12px 200px 8px 294px;
        border: 1px solid #D2D9DB;
        position: relative;
        @media only screen and (max-width: $screen-lg - 1px) {
            padding: 12px 177px 8px 239px;
        }
        @media only screen and (max-width: $screen-sm - 1px) {
            height: 217px;
            padding: 10px;
            height: 217px;
            padding: 10px 10px 10px 140px;
        }
    }
    &__photo {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        &::after {
            content: '';
            display: block;
            width: 100%;
            padding: 37.2%;
        }
        &--start {
            width: 243px;
            position: absolute;
            top: 8px;
            left: 10px;
            &::after {
                padding: 29.8%;
            }
            @media only screen and (max-width: $screen-lg - 1px) {
                transform: scale(.89);
                left: 0;
            }
            @media only screen and (max-width: $screen-sm - 1px) {
                transform: scale(0.5);
                left: -50px;
                top: -25px;
            }
        }
        &--small {
            width: 32%;
            margin-right: 2%;
            float: left;
            margin-top: 16px;
            &:nth-child(3n + 1) {
                margin-right: 0;
            }
        }
    }
    &__description {
        &--content {
            padding: 25px 16px;
            float: left;
            width: 50%;
            overflow: hidden;
            @media only screen and (max-width: $screen-md - 1px) {
                width: 100%
            }
        }
    }
    &__title {
        font-weight: 600;
        font-size: 39px;
        margin-bottom: 20px;
        &--start {
            font-size: 20px;
            @media only screen and (max-width: $screen-lg - 1px) {
                margin-bottom: 5px;
            }
            @media only screen and (max-width: $screen-sm - 1px) {
                line-height: 20px;
            }
        }
    }
    &__text {
        font-size: 16px;
        @media only screen and (max-width: $screen-lg - 1px) {
            font-size: 14px;
            line-height: 17px;
        }
    }
    &__button {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 169px;
        background-color: $default-color;
        color: white;
        cursor: pointer;
        text-align: center;
        padding: 53px 36px;
        font-size: 20px;
        font-weight: 500;
        @media only screen and (max-width: $screen-sm - 1px) {
            left: 10px;
            top: 95px;
            width: 123px;
            padding: 17px 10px;
            bottom: 10px;
        }
        &:hover, &:active, &:focus {
            color: white;
            text-decoration: none;
        }
        &::after {
            content: '';
            display: block;
            width: 0;
            border: 18px solid transparent;
            border-bottom-width: 0;
            border-top-color: white;
            position: absolute;
            bottom: 9px;
            left: 50%;
            margin-left: -18px;
        }
    }

    &__content {
        display: none;
        background-color: white;
        padding: 0 24px;
        margin: 0;
        position: relative;
        border: 1px solid #D2D9DB;
        margin: 50px 0;
        @media only screen and (max-width: $screen-sm - 1px) {
            padding: 0;
        }
        &::after {
            content: '';
            display: block;
            clear: both;
        }
        &::before {
            content: '';
            border: 40px solid transparent;
            border-top-width: 0;
            border-bottom-color: white;
            position: absolute;
            top: -40px;
            left: 50%;
            margin-left: -40px;
        }
        &--parent {
            padding: 0;
            border: 0px solid transparent;
            background-color: transparent;
            margin: 0;
            &::before {
                display: none;
            }
        }
    }
    &__gallery {
        float: left;
        width: 50%;
        padding: 28px;
        overflow: hidden;
        @media only screen and (max-width: $screen-md - 1px) {
            width: 100%
        }
    }
    &__form-button {
        font-size: 29px;
        font-weight: 500;
        color: white;
        padding: 30px;
        padding: 36px 10px;
        background-color: $default-color;
        text-align: center;
        margin-top: 53px;
        display: block;
        transition: background-color .4s;
        &:hover, &:active, &:focus {
            color: white;
            text-decoration: none;
            background-color: darken($default-color, 5%);
        }
        &::after {
            content: '';
            width: 0;
            border: 18px solid transparent;
            border-bottom-width: 0;
            border-top-color: white;
            display: inline-block;
            vertical-align: middle;
            margin-left: 20px;
        }
    }
    &--child {
        margin: 0;
        .offer {
            &__start {
                background-color: #D2D9DB;
                &::before {
                    content: '';
                    border: 40px solid transparent;
                    border-top-width: 0;
                    border-bottom-color: #D2D9DB;
                    position: absolute;
                    top: -40px;
                    left: 50%;
                    margin-left: -40px;
                }
            }
        }
    }
    // &--open {
    //     .offer {
    //         &__content {
    //             display: block;
    //             overflow: visible;
    //             max-height: initial;
    //             opacity: 1;
    //             transition: opacity .6s, margin .6s;
    //             margin: 50px 0;
    //         }
    //         &__description {
    //             &--content {
    //                 max-height: 1000px;
    //                 transition: max-height .6s;
    //             }
    //         }
    //         &__gallery {
    //             max-height: 1000px;
    //             transition: max-height .6s;
    //         }
    //     }
    // }
}