.sprite {
    background-image: url(spritesheet.png);
    background-repeat: no-repeat;
    display: block;
}

.sprite--home {
    width: 42px;
    height: 36px;
    background-position: -5px -5px;
}

.sprite--home-hover {
    width: 42px;
    height: 36px;
    background-position: -57px -5px;
}

.sprite--logo {
    width: 180px;
    height: 121px;
    background-position: -109px -5px;
}

.sprite--osoba1 {
    width: 119px;
    height: 119px;
    background-position: -299px -5px;
}

.sprite--osoba2 {
    width: 119px;
    height: 119px;
    background-position: -428px -5px;
}

.sprite--osoba3 {
    width: 119px;
    height: 119px;
    background-position: -557px -5px;
}

.sprite--osoba4 {
    width: 119px;
    height: 119px;
    background-position: -686px -5px;
}

.sprite--polecam {
    width: 287px;
    height: 249px;
    background-position: -299px -134px;
}

.sprite--strzalka {
    width: 90px;
    height: 156px;
    background-position: -5px -134px;
}

.sprite--usp1 {
    width: 233px;
    height: 74px;
    background-position: -596px -134px;
}

.sprite--usp1a {
    width: 335px;
    height: 335px;
    background-position: -5px -393px;
}

.sprite--usp2 {
    width: 109px;
    height: 67px;
    background-position: -105px -218px;
}

.sprite--usp2a {
    width: 335px;
    height: 335px;
    background-position: -350px -393px;
}

.sprite--usp3 {
    width: 97px;
    height: 83px;
    background-position: -596px -218px;
}

.sprite--usp3a {
    width: 335px;
    height: 335px;
    background-position: -839px -5px;
}

.sprite--usp4 {
    width: 141px;
    height: 48px;
    background-position: -695px -350px;
}

.sprite--usp4a {
    width: 335px;
    height: 335px;
    background-position: -846px -350px;
}
