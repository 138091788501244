.footer {
    width: 100%;
    padding: 10px;
    background-color: #F5F5F5;
    font-size: 0;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    &__section {
        color: #878787;
        font-size: 13px;
        width: 640px;
        display: inline-block;
        vertical-align: top;
        text-align: left;
        @media only screen and (max-width: $screen-md - 1px) {
            width: 370px;
        }
        @media only screen and (max-width: $screen-sm - 1px) {
            width: 100%;
            padding-bottom: 10px
        }
        &--right {
            @media only screen and (min-width: $screen-lg) {
                text-align: right;
            }
            @media only screen and (max-width: $screen-md - 1px) {
                text-align: right;
            }
            @media only screen and (max-width: $screen-sm - 1px) {
                text-align: left;
            }
        }
    }
    &__link {
        color: #878787;
        &:hover, &:active, &:focus {
            color: #878787;
        }
    }
    &__optimal {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        @media only screen and (max-width: $screen-sm - 1px) {
            display: block;
            margin: 4px 0;
        }
    }
}