/*-- CLASS FOR INHERIT START --*/
h2 {
    font-size: 22px;
    font-weight: 600;
}


.content {
    width: 100%;
    overflow-x: hidden;
    font-family: 'Khand', sans-serif; 
    color: $light-text;
}
.fontAwesome {
    font-size: 36px;
    display: block;
    float: left;
    margin: 0px 10px 0px -3px;
}
.tinymceContent {
    //
}

.carousel-control {
    background-image: initial !important;
    background-color: rgba(122, 129, 125, 0.5);
    border: 1px solid white;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    margin: -35px 4%;
    top: 50%;
    padding: 19px;
    @media only screen and (max-width: $screen-sm - 1px) {
        transform: scale(.5);
        &.left {
            left: -25px;
        }
        &.right {
            right: -25px;
        }
    }
}
.carousel-caption {
    text-align: left;
    top: 38%;
    @media only screen and (max-width: $screen-lg - 1px) {
        top: 30%;
    }
    @media only screen and (max-width: $screen-sm - 1px) {
        top: 5%;
    }
    &__title {
        font-weight: 600;
        font-size: 54px;
        @media only screen and (max-width: $screen-lg - 1px) {
            font-size: 28px;
            line-height: 22px;
        }
        @media only screen and (max-width: $screen-sm - 1px) {
            font-size: 18px;
        }
    }
    &__subtitle {
        font-size: 25px;
        font-weight: 100;
        @media only screen and (max-width: $screen-lg - 1px) {
            font-size: 17px;
        }
        @media only screen and (max-width: $screen-sm - 1px) {
            font-size: 14px;
        }
    }
}
.arrow  {
    &--left {
        @include arrow(left, 15px, 3px, white);
    }
    &--right {
        @include arrow(right, 15px, 3px, white);
    }
}
.column-2 {
    width: 50%;
    padding: 0 25px;
    float: left;
    @media only screen and (max-width: $screen-sm - 1px) {
        width: 100%;
        padding-bottom: 30px;
    }
}
.column-4 {
    width: 25%;
    padding: 0 25px;
    float: left;
    @media only screen and (max-width: $screen-lg - 1px) {
        width: 50%;
    }
    @media only screen and (max-width: $screen-sm - 1px) {
        width: 100%;
        padding-bottom: 30px;
    }
    &--to-full {
        @media only screen and (max-width: $screen-lg - 1px) {
            width: 100%;
            padding-bottom: 30px;
        }
    }
}
.text {
    font-size: 18px;
    padding-bottom: 50px;
    font-weight: 400;
    
    &__bold {
        font-weight: 600;
        display: inline;
    }
    &--bright {
        color: white;
        text-align: center;
        padding: 0 25px 40px;
        font-weight: 100;
        font-size: 20px;
    }
}
.photo {
    width: 100%;
    padding: 0 2px 4px;
}
.center-containter {
    max-width: 960px;
    margin: 0 auto;
}
.google-map {
    width: 100%;
    height: 848px;
}
.banner {
    width: 100%;
}
.search-input {
    width: 100%;
    padding: 5px;
    font-size: 19px;
}