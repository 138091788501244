#productModule {
	.categoriesChildren {
        display: none;
    }

    #categoryTree {
    	margin-top: 25px;
    	padding-left: 10px;
    	a {
    		display: table-cell;
    		color: #000;
    	}
		.expand {
			display: table-cell;
		}
	    a.checked {
	        color: #000;
	        font-weight: bold;
	    }
	    li {
			list-style: none;
	        padding-left: 0;
	        ul {
	        	padding-left: 20px;
	        }
		    .active{
		        color: $default-color;
		        font-weight: bold;
		    }
		    .expand i {
		    	font-size: 20px;
		    	line-height: 10px;
		    	padding-left: 10px;
		    }
	    }
    }

    .productsList {
    	margin-top: 20px;
    	a.product {
    		display: block;
    		img {
    			padding: 5px;
    		}
    		.description {
    			padding: 15px;
    			.name {
    				font-size: 20px;
    			}
    			.price {
    			}
    		}
    	}
    	a.product:hover {
    		background: $default-color;
    		color: #FFF;
    	}
    }

    #product {
    	padding-top: 15px;
    	.price {
    		color: $default-color;
    		font-size: 20px;
    	}
    	.shortDescript {

    	}
    	.description {
    		padding-top: 20px;
    	}
    	.images div{
    		img {
    			padding: 10px;
    		}
    	}
    }
}