.map {
    width: 100%;
    height: 876px;
    &__marker-desc {
        padding: 14px 0;
        height: 185px;
        font-size: 24px;
        line-height: 28px;
    }
    &__title {
        font-weight: 700;
        line-height: initial;
    }
    &__href {
        color: black;
        font-weight: 700;
    }
}