@mixin arrow($direction, $size, $thick, $color, $duration: .4s) {
    display: inline-block;
    position: relative;
    &::before, &::after {
        content: '';
        display: block;
        width: $size * 1.414;
        height: $thick;
        background-color: $color;
        position: absolute;
        transform: rotate(45deg);
        margin-top: $size * 0.4 - ($thick / 7);
        margin-bottom: $size * 0.4 - ($thick / 7);
        margin-left: $size * -0.24;
        margin-right: $size * -0.24;
        transition: transform $duration, background-color $duration;
    }
    &::after {
        transform: rotate(-45deg);
    }

    @if  $direction == 'top'{
        width: $size * 1.8;
        height: $size;
        &::before {
            transform: rotate(-45deg);
        }
        &::after {
            right: 0;
            transform: rotate(45deg);
        }
    } @else if  $direction == 'right'{
        width: $size;
        height: $size * 1.8;
        &::after {
            bottom: 0;
        }
    } @else if $direction == 'down' {
        width: $size * 1.8;
        height: $size;
        &::after {
            right: 0;
        }
    } @else if  $direction == 'left'{
        
        width: $size;
        height: $size * 1.8;
        &::before {
            transform: rotate(-45deg);
        }
        &::after {
            bottom: 0;
            transform: rotate(45deg);
        }
    }
}