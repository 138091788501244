.menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.3);
    &__content {
        height: 166px;
        background-color: white;
        margin: 0 auto;
        text-align: right;
        width: 1190px;
        max-width: 100%;
        padding: 30px 37px 10px 0;
        position: relative;
        @media only screen and (max-width: $screen-lg - 1px) {
            height: 75px;
            padding-top: 10px;
        }
    }
    &__dropdown {
        @media only screen and (max-width: $screen-md - 1px) {
            display: none;
            width: 180px;
            position: absolute;
            background-color: white;
            top: 75px;
            right: 0;
            box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.3);
            &::before {
                content: '';
                position: absolute;
                display: block;
                left: -15px;
                right: 0;
                height: 15px;
                top: -15px;
                background-color: white;;
            }
        }
        &--active {
            display: block;
        }
    }
    &__item {
        color: transparent;
        border: 3px solid transparent;
        transition: color .4s, border-color .4s, font-weight .4s;
        font-size: 17px;
        line-height: 24px;
        text-transform: uppercase;
        padding: 13px 17px;
        margin: 9px;
        height: 56px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        font-weight: 600;
        @media only screen and (max-width: $screen-lg - 1px) {
            font-size: 17px;
            padding: 13px 12px;
            margin: 0 6px;
        }
        @media only screen and (max-width: $screen-md - 1px) {
            text-align: left;
            width: 100%;
            margin: 0;
        }
        &:hover, &:active, &:focus {
            color: transparent;
            .menu {
                &__text {
                    text-decoration: none;
                }
            }
        }
        &:hover {
            .menu {
                &__text {
                    font-weight: 600;
                }
            }
        }
        &--home {
            font-size: 0;
            padding-top: 3px;
            &::after {
                content: '';
                @extend .sprite;
                @extend .sprite--home;
                display: inline-block;
            }
        }
        &--active {
            .menu {
                &__text {
                    color: $second-color;
                }
            }
            &::after {
                content: '';
                @extend .sprite--home-hover;
            }
        }
    }
    &__text {
        width: 100%;
        transition: color .4s, border-color .4s, font-weight .4s;
        color: #4A4A4A;
        font-size: 17px;
        position: absolute;
        left: 0;
        text-align: center;
        margin-left: 0;
        @media only screen and (max-width: $screen-lg - 1px) {
            font-size: 17px;
        }
        @media only screen and (max-width: $screen-md - 1px) {
            text-align: left;
            font-weight: 600;
            width: 100%;
            margin: 0;
            font-size: 21px;
            padding-left: 12px;
        }
    }
    &__logo {
        background-image: url('/media/default/logo.png');
        background-repeat: no-repeat;
        width: 437px;
        height: 134px;
        position: absolute;
        top: 15px;
        left: -40px;
        @media only screen and (max-width: $screen-lg - 1px) {
            transform: scale(0.5);
            top: -30px;
            left: -70px;
        }
        @media only screen and (max-width: $screen-sm - 1px) {
            left: -100px;
        }
    }
    &__phone {
        display: none;
        @media only screen and (max-width: $screen-md - 1px) {
            display: inline-block;
            font-size: 19px;
            text-transform: uppercase;
            font-weight: 700;
            color: $second-color;
            position: absolute;
            right: 70px;
            top: 16px;
        }
    }
    &__button {
        cursor: pointer;
        width: 50px;
        height: 33px;
        border-radius: 2px;
        padding: 3px 8px;
        transition: background-color .4s;
        position: relative;
        &::before {
            content: '';
            display: block;
            width: 32px;
            height: 16px;
            border-top: 6px solid $second-color;
            border-bottom: 6px solid $second-color;
            transition: border-color .4s, transform .4s;
        }
        &::after {
            content: '';
            display: block;
            width: 32px;
            height: 10px;
            border-bottom: 6px solid $second-color;
            transition: border-color .4s, transform .4s;
        }
        &--active {
            &::before, &::after {
                height: 0;
                position: absolute;
                top: 50%;
                margin-top: -2px;
                left: 50%;
                margin-left: -16px;
                border-top-width: 0;
                transform: rotate(38deg);
            }
            &::after {
                transform: rotate(-38deg);
            }
        }
    }
    &__search-button {
        display: block;
        font-size: 23px;
        position: absolute;
        right: 5px;
        padding: 13px;
        color: #4A4A4A;
        border: 0px solid transparent;
        background: none;
        top: 38px;
        @media only screen and (max-width: $screen-lg - 1px) {
            top: 10px;
        }
        @media only screen and (max-width: $screen-md - 1px) {
            top: 5px;
        }
        &::after {
            content: '';
            display: block;
            width: 1px;
            height: 20px;
            background-color: #4A4A4A;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -12px;
        }
    }
    &--fixed {
        position: fixed;
        .menu {
            &__logo {
                background-image: url('/media/default/logo--fixed.png');
                @media only screen and (max-width: $screen-lg - 1px) {
                    transform: scale(0.8);
                    top: -7px;
                    left: -40px;
                }
                @media only screen and (max-width: $screen-sm - 1px) {
                    transform: scale(0.5);
                    top: -17px;
                    left: -115px;
                }
            }
            &__content {
                padding-top: 0;
                height: 90px;
                @media only screen and (max-width: $screen-lg - 1px) {
                    height: 60px;
                    padding-top: 10px;
                }
            }
            &__search-button {
                top: 10px;
                @media only screen and (max-width: $screen-lg - 1px) {
                    top: 0;
                }
                @media only screen and (max-width: $screen-md - 1px) {
                    top: 5px;
                }
            }
        }
    }
}