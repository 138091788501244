.form {
    border-top: 1px solid $light-text;
    color: $light-text;
    padding: 0px 70px;
    max-width: 740px;
    margin: 0px auto;
    font-size: 0;
    position: relative;
    width: 100%;
    text-align: left;
    @media only screen and (max-width: $screen-sm - 1px) {
        padding: 20px 0;
    }
    &::after {
        content: '';
        display: block;
        clear: both;
    }
    &__title {
        font-weight: 500;
        font-size: 54px;
        text-align: center;
    }
    &__select-desc {
        font-size: 16px;
        text-align: center;
        padding: 30px 0 10px;
    }
    &__select-wrapper {
        position: relative;
        &::before {
            content: '';
            display: block;
            border: 1px solid #CECED0;
            top: 4px;
            right: 4px;
            width: 40px;
            height: 40px;
            position: absolute;
            pointer-events: none;
        }
        &::after {
            content: '';
            display: block;
            border: 10px solid transparent;
            border-top: 9px solid black;
            top: 20px;
            right: 15px;
            position: absolute;
            pointer-events: none;
        }
    }
    &__select {
        width: 100%;
        min-height: 48px;
        background-color: rgba(255, 255, 255, .74);
        font-size: 24px;
        line-height: 46px;
        border: 1px solid #CECED0;
        margin-bottom: 25px;
        color: black;
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        padding-left: 4px;
        padding-right: 41px;
    }
    &__label {
        display: inline-block;
        width: 120px;
        line-height: 48px;
        text-align: right;
        font-size: 16px;
        font-weight: 400;
        padding-right: 17px;
        vertical-align: top;
        position: relative;
        @media only screen and (max-width: $screen-sm - 1px) {
            text-align: center;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        &--required {
            &::after {
                content: '*';
                position: absolute;
                top: -2px;
                right: 6px;
            }
        }
    }
    &__input {
        min-height: 48px;
        background-color: rgba(255, 255, 255, .74);
        font-size: 24px;
        line-height: 46px;
        border: 1px solid #CECED0;
        width: 480px;
        max-width: 100%;
        margin-bottom: 10px;
        color: $light-text;
        padding-left: 4px;
        @media only screen and (max-width: $screen-sm - 1px) {
            width: 100%;
        }
        &--error {
            border-color: #a91212;
        }
    }
    &__required {
        font-size: 16px;
        width: 100%;
        font-style: italic;
        line-height: 48px;
        text-align: left;
        padding-left: 120px;
    }
    &__send {
        float: right;
        width: 254px;
        line-height: 69px;
        text-transform: uppercase;
        font-size: 26px;
        text-align: center;
        background-color: $default-color;
        transition: background-color 0.4s;
        border-width: 0;
        color: white;
        @media only screen and (max-width: $screen-sm - 1px) {
            float: none;
            display: block;
            margin: 0 auto;
        }
        &:hover, &:active {
            background-color: darken($default-color, 5%);
        }
    }
    &__info {
        width: 100%;
        padding: 10px;
        text-align: center;
        font-weight: 600;
        &--success {
            color: $second-color;
            border: 3px solid $second-color;
            font-size: 24px;
        }
        &--error {
            font-size: 18px;
            color: #a91212;
            top: -10px;
            position: relative;
        }
    }
    &__hidden-link {
        width: 0;
        height: 0;
    }
    &__checkbox {
        width: 30px;
        height: 30px;
        display: inline-block;
        position: relative;
        margin-top: 18px !important;
        @media only screen and (min-width: $screen-sm) {
            margin-left: 50px !important;
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            background-color: white;
            border: 2px solid $default-color;
            z-index: 1;
        }
        &:checked {
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 6px;
                left: 5px;
                width: 18px;
                height: 12px;
                border-left: 3px solid $default-color;
                border-bottom: 3px solid $default-color;
                transform: rotate(-45deg);
                z-index: 2;
            }
        }
        &--error {
            &::before {
                border-color: #a91212;
            }
            &:checked {
                &::before {
                    border-color: $default-color;
                }
            }
        }
    }
    &__checkbox-desc {
        margin: -39px 0 5px 40px;
        color: $light-text;
        font-weight: 400;
        font-size: 16px;
        @media only screen and (min-width: $screen-sm) {
            margin-left: 120px;
        }
    }
    &--sended {
        opacity: .4;
        .form {
            &__send {
                background-color: lighten($default-color, 20%);
                pointer-events: none;
            }
        }
    }
}