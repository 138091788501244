.section {
    position: relative;
    &::after {
        content: '';
        display: block;
        clear: both;
    }
    &--menu-placeholder {
        padding-top: 165px;
        @media only screen and (max-width: $screen-lg - 1px) {
            padding-top: 75px;
        }
    }
    &--story {
        padding: 0 15px 0px;
        background-color: #F5F5F5;
    }
    &--offer {
        background-image: url('/media/default/background-offer.jpg');
        background-color: #E7EBEC;
        padding-bottom: 80px;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center 60px;
        background-size: 100%;
    }
    &--service {
        background-image: url('/media/default/background-service.jpg');
        background-color: #E7EBEC;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 70px;
    }
    &--opinion {
        padding-bottom: 40px;
    }
    &--contact {
        background-image: url('/media/default/background-contact.jpg');
        background-repeat: repeat-y;
        background-position: center;
        background-size: cover;
        background-color: white;
        padding-bottom: 50px;
    }
}