
$red: #CC212C;
$blue: #009DFE;
.contact {
    text-align: center;
    font-size: 0;
    padding: 20px 10px 10px;
    &__item {
        width: 100%;
        max-width: 280px;
        display: inline-block;
        margin-bottom: 30px;
    }

    &__content {
        display: inline-block;
        vertical-align: top;
        color: $light-text;
        font-size: 21px;
        text-align: left;
    }
    &__title {
        font-size: 24px;
        font-weight: 600;
        @media only screen and (max-width: $screen-sm - 1px) {
            text-align: center;
        }
    }
    &__subtitle {
        font-size: 18px;
        position: relative;
        top: -4px;
    }
    &__text {
        font-size: 21px;
        line-height: 26px;
        font-weight: 400;
    }
    &__important {
        font-size: 25px;
        font-weight: 700;
        margin-right: 25px;
        display: inline;
    }
    &__left {
        padding-left: 10px;
        float: right;
    }
    &__link {
        color: $light-text;
        &:hover, &:active, &:focus {
            color: $light-text;
        }
    }
}
.contact-form{
    background-color: $red;
    background-image: url('/media/default/formbackground.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    width: 100%;
    height: auto;
    min-height: 820px;
    &--contact-form
    {
        padding-top: 604px;
        margin-top: -400px;

        @media (max-width:1024px){
            margin-top:-200px;
            padding-top: 420px;
        }
    }
    @media screen and (max-width: $screen-md){
        background-size: 100%;
    }

    &__oldphone{
        background: url('/media/default/oldphone.png') no-repeat;
        width: 75px;
        height: 61px;

        @media screen and (max-width: $screen-md){
            margin-left: 25px;
        }

        &::before{
            content: '';
            position: absolute;
            top: 58px;
            left: 0;
            width: 0;
            height: 0;
            border-top: 25px solid transparent;
            border-bottom: 25px solid transparent;
            border-left: 33px solid white;
        }
    }

    &--phone{
        background-image: none;
        min-height: 0px;
        padding: 0 55px;
        overflow: auto;
        padding-top: 50px;
        padding-bottom: 141px;

        @media screen and (max-width: $screen-md){
            padding: 50px 15px;
            padding-bottom: 100px;
        }


        @media (max-width:$screen-sm - 1px){
            padding-bottom:35px;
            padding-top:35px;
        }
    }

    &__title{
        text-align: center;
        width: 100%;
        color: #520E12;
        font-size: 56px;
        font-family: Ubuntu-B;
        padding-top: 25px;
        padding-bottom: 25px;
        position: relative;
        top:-240px;
        @media screen and (max-width: $screen-sm){
            font-size: 36px;
            line-height: 36px;
        }

        &--phone{
            text-align: left;
            font-size: 46px;
            line-height: 52px;
            position:static;

            @media screen and (max-width: $screen-md){
                font-size: 26px;
                line-height: 26px;
                padding: 0 20px;
                margin-top: 10px;
                margin-bottom: 20px;
            }

            span{
                color: black;
            }
        }
    }

    &__wrapper{
        border-top: 1px solid #333333;
        color: #333333;
        padding: 20px 70px;
        max-width: 740px;
        margin: 40px auto;
        font-size: 0;
        position: relative;
        width: 100%;
        text-align: left;
        //width: 80%;
        ////padding-bottom: 50px;
        //position: relative;
        ////top: -185px;
        //padding-bottom: 45px;
        //
        //@media (max-width:1024px){
        //    margin-top:-50px;
        //}
        //
        //@media screen and (max-width: $screen-sm){
        //    width: 95%;
        //}
    }

    &__label{
        color: black;
        font-size: 20px;
        font-family: Ubuntu-R;
        font-weight: normal;
        margin-top: 15px;

        &--checkbox{
            font-size: 14px;
            display: inline;
        }

        &--require{
            font-size: 14px;
            text-align: right;
            margin-bottom: 15px;
        }

        &--expandable{
            display: none;
        }

        &--visible{
            display: inline;
        }
    }

    &__input{
        width: 100%;
        outline: none;
        border: none;
        height: 40px;
        font-family: Ubuntu-R;
        font-style: italic;
        font-size: 24px;
        color: black;
        padding: 0 10px;

        &:focus{
            outline: none;
        }

        &--textarea{
            height: 216px;
        }

        &--phone{
            height: 71px;
        }
    }

    &__checkbox{
        margin-right: 10px !important;
    }

    &__checkbox-wrapper{
        margin-top: 20px;
    }

    &__more-button{
        display: inline;
        font-weight: bold;
        cursor: pointer;
    }

    &__submit{
        width: 161px;
        height: 57px;
        background: none;
        color: black;
        font-size: 24px;
        font-family: Ubuntu-B;
        outline: none;
        border: solid 3px white;
        float: right;
        font-weight: bold;

        @media screen and (max-width: $screen-sm){
            float: none;
        }

        &:hover{
            background-color: white;
            color: $red;
        }

        &--sm{
            @media (max-width:$screen-md - 1px){
                margin-top:20px;
            }

            @media (max-width:$screen-sm - 1px){
                margin-top:0;
            }
        }
    }

    &__button-wrapper{
        @media screen and (max-width: $screen-sm){
            display: block;
            margin: 0 auto;
            width: 160px;
            margin-top: 10px;
        }

        &--phone{
            @media screen and (max-width: $screen-md){
                display: block;
                margin: 0 auto;
                width: 160px;
                margin-top: 120px;
            }

            @media (max-width:$screen-sm - 1px){
                margin-top:10px;
            }
        }
    }

    &__forget{
        display:block;
        color:black;
        font-weight: 400;
        font-size: 16px;
        margin-top:20px;
        padding-top: 15px;
        padding-bottom: 25px;

        &:hover,&:focus{
            color:black;
            text-decoration:none;
        }
    }

    &__forget-form{
        margin-top:20px;
        display:none;
    }

    &__forgetData-form{
        max-width:730px;
        display:none;
    }

    &__forget-input{
        margin-bottom:20px;
        &--ru{
            &::placeholder{
                font-size:14px;
            }
        }

        &--en{
            &::placeholder{
                font-size:16px;
            }
            font-size:16px;
        }

        &--pl, &--ua, &--cz{
            &::placeholder{
                font-size:18px;
            }
        }


    }

    &__forget-button{
        @media(max-width:1025px){
            margin-top:105px;

            button{
                display: block;
                float: none;
                margin: 0 auto
            }
        }
    }





}