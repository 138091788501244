.title {
    font-weight: 100;
    font-size: 54px;
    color: $default-color;
    text-align: center;
    max-width: $break;
    padding: 47px 10px 0px;
    margin: 0 auto;
    @media only screen and (max-width: $screen-lg - 1px) {
        padding-bottom: 30px;
        font-size: 45px;
    }
    @media only screen and (max-width: $screen-sm - 1px) {
        padding-bottom: 30px;
        font-size: 29px;
    }
    &__sub {
        position: relative;
        color: $light-text;
        font-weight: 400;
        font-size: 20px;
        padding: 30px 0;
    }
    &--first {
        color: $light-text;
        .title {
            &__sub {
                font-size: 20px;
                &::before {
                    content: '';
                    display: block;
                    height: 1px;
                    background-color: $light-text;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                &::after {
                    content: '';
                    display: block;
                    height: 1px;
                    background-color: $light-text;
                    position: absolute;
                    left: 40px;
                    right: 40px;
                    bottom: -5px;
                }
            }
        }
    }
    &--bright {
        .title {
            &__sub {
                color: white;
            }
        }
    }
}