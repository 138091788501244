.file {
    padding-top: 15px;
    font-weight: 600;
    &__name {
        font-size: 22px;
    }
    &__link {
        font-size: 19px;        
    }
    &__icon {
        display: inline-block;
        vertical-align: middle;
    }
    &__download {
        display: inline-block;
        vertical-align: middle;
        color: $light-text;
        margin-left: 5px;
    }
}