.rotate {
    display: none;
    &__ball {
        opacity: 1;
        -webkit-animation: rotating 1.5s linear infinite;
        -moz-animation: rotating 1.5s linear infinite;
        -ms-animation: rotating 1.5s linear infinite;
        -o-animation: rotating 1.5s linear infinite;
        animation: rotating 1.5s linear infinite;
        height: 120px;
        width: 16px;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -60px;
        margin-left: -8px;
        z-index: 2;
        &::after {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-color: $light-text;
            border-radius: 100%;
        }
    }
    &__loading {
        display: block;
        opacity: 1;
        width: 80px;
        height: 20px;
        color: $light-text;
        position: fixed;
        text-align: center;
        top: 50%;
        left: 50%;
        margin-top: -10px;
        margin-left: -40px;
        z-index: 2;
        font-size: 16px;
    }
    &--active {
        display: block;
    }
}