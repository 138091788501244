.opinion {
    width: 1140px;
    max-width: 100%;
    padding: 0 0 40px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    font-weight: 100;
    &::after {
        content: " ";
        display: block;
        clear: both;
    }
    &::before {
        content: " ";
        display: block;
        position: absolute;
        bottom: 30px;
        left: 50%;
        margin-left: -192px;
        width: 384px;
        border-bottom: 1px solid #C8C8C8;   
        @media only screen and (max-width: $screen-sm - 1px) {
            width: 300px;
            margin-left: -150px;
        }     
    }
    &__content {
        padding: 10px;
        position: relative;
        font-size: 20px;
        text-align: center;
    }
    &__name {
        font-size: 20px;
        text-align: center;
        font-style: italic;
    }
    &__avatar {
        height: 60px;
        width: 60px;
        border-radius: 100%;
        background-position: center;
        background-size: contain;
        background-color: lighten($default-color, 40);
        margin: 40px 0;
        float: right;
    }
}